<template>
  <div>
    <!-- alert colors-->
    <v-card
      title="Colors"
      class="mb-6"
    >
      <v-card-text>
        View Maps
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  components: {},
  setup() {},
}
</script>
